import React from "react"
import { Container, Card, Row, Col } from "react-bootstrap"
import SEO from "../components/seo"
import Layout from "../components/layout"
import "./releases.css"

const Releases = () => {
  return (
    <>
      <SEO title="Release Notes - TeamCoaches" />
      <Layout>
        <Container style={{ paddingLeft: 120, paddingRight: 120 }}>
          <h1>Release Notes</h1>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={4} sm={4} md={2} lg={1}>
                  <Card.Text>
                    <b>v.0.7</b>
                  </Card.Text>{" "}
                </Col>
                <Col xs={7} sm={8} md={10} lg={11}>
                  <Card.Text>10/04/2020</Card.Text>
                  <Card.Text style={{ marginTop: -20 }}>Description</Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </Layout>
    </>
  )
}

export default Releases
